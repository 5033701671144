import { NgZone } from '@angular/core';

import { environment } from '../../environments/environment';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFireDatabase } from '@angular/fire/database';

export function AngularFirestoreAuth(platformId: NonNullable<unknown>, zone: NgZone) {
  const currentConfig =
    environment.production === 'true'
      ? environment.firebaseAuthConfigProd
      : environment.firebaseAuthConfigDev;
  return new AngularFireAuth(
    currentConfig,
    'auth',
    platformId,
    zone,
    null,
    null,
    null,
    null,
    null,
    null
  );
}

export function AngularFirestoreAttendance(platformId: NonNullable<unknown>, zone: NgZone) {
  const currentConfig =
    environment.production === 'true'
      ? environment.firebaseAttendanceConfigProd
      : environment.firebaseAttendanceConfigDev;
  return new AngularFireDatabase(currentConfig, 'attendance', null, platformId, zone, null, null);
}
