import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import sanitizeHtml from 'sanitize-html';

@Pipe({
  name: 'safeHtml',
})
export class SafeHtmlPipe implements PipeTransform {


  constructor(private sanitizer: DomSanitizer) {}

  transform(value) {

    const sanitizedValue = sanitizeHtml(value, {
      allowedTags: ['p', 'span', 'strong', 'em', 'ul', 'li', 'img', 'br'], // Define tags permitidas
      allowedAttributes: {
        'span': ['style'],
        'img': ['src', 'alt', 'width', 'height'], // Permite apenas atributos seguros
      },
      allowedSchemes: ['http', 'https'] // Apenas URLs seguras
    });
    return this.sanitizer.bypassSecurityTrustHtml(sanitizedValue);
  }
}
