export const environment = {
  production: 'false',
  version: 'Development Version',
  api: 'https://dev.mihmo.com.br/loki/api',
  apiNest: 'https://dev.mihmo.com.br/backend-nest',
  firebaseAuthConfigProd: {
    apiKey: 'AIzaSyB2pZ3m0D__RDyygGJaZa-1wuq90zb5uss',
    authDomain: 'loki-77f14.firebaseapp.com',
    databaseURL: 'https://loki-77f14-default-rtdb.firebaseio.com',
    projectId: 'loki-77f14',
    storageBucket: 'loki-77f14.appspot.com',
    messagingSenderId: '933091524473',
    appId: '1:933091524473:web:518af1dc953300ee6d79a8',
    measurementId: 'G-3VK0XSCKPH',
  },
  firebaseAuthConfigDev: {
    apiKey: 'AIzaSyDgFSh08vXvLcYZTcPKiAimhpJy4MGiHNM',
    authDomain: 'loki-dev-4448e.firebaseapp.com',
    databaseURL: 'https://loki-dev-4448e-default-rtdb.firebaseio.com',
    projectId: 'loki-dev-4448e',
    storageBucket: 'loki-dev-4448e.appspot.com',
    messagingSenderId: '711855580583',
    appId: '1:711855580583:web:5867d64a623efd73fd4282',
    measurementId: 'G-6W6VLFX9JE',
  },
  firebaseAttendanceConfigProd: {
    apiKey: 'AIzaSyA12GtEDvsCmJtX_LbvA6w311pfks1dxhs',
    authDomain: 'attendances-f2347.firebaseapp.com',
    databaseURL: 'https://attendances-f2347-default-rtdb.firebaseio.com',
    projectId: 'attendances-f2347',
    storageBucket: 'attendances-f2347.appspot.com',
    messagingSenderId: '708905184227',
    appId: '1:708905184227:web:60c70d581335c889788ed6',
    measurementId: 'G-7RCEE7L0F8',
  },
  firebaseAttendanceConfigDev: {
    apiKey: 'AIzaSyAdEg2dKk8c4wpVRkpdGjP6jvWJGOJSZYg',
    authDomain: 'attendances-dev.firebaseapp.com',
    projectId: 'attendances-dev',
    storageBucket: 'attendances-dev.appspot.com',
    messagingSenderId: '1020571895039',
    appId: '1:1020571895039:web:aeb8afae2e53af0911f8ca',
  },
  memedConfig: {
    embeddedUrl:
      'https://integrations.memed.com.br/modulos/plataforma.sinapse-prescricao/build/sinapse-prescricao.min.js',
  },
};
