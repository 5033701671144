import { Component } from '@angular/core';

@Component({
  selector: 'recriar-root',
  template: `<router-outlet></router-outlet>
    <router-outlet name="modal"></router-outlet>`,
})
export class AppComponent {
  title = 'admin';
}
