import { Inject, Injectable } from '@angular/core';
import { LoginForm } from '../models/login-form';
import { UserService } from './user.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { HttpService } from '@recriar/titan';
import { TranslocoService } from '@ngneat/transloco';
import { AuthMenuAndPermissions, Literals, UserModel } from '../models';
import { LiteralsService } from './literals.service';
import {
  catchError,
  from,
  Observable,
  Subject,
  switchMap,
  throwError,
} from 'rxjs';
import { AngularFireAuth } from '@angular/fire/auth';
import { environment } from '../../../../../apps/admin/src/environments';
import {
  PoNotificationService,
  PoToasterOrientation,
} from '@po-ui/ng-components';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  literals: Literals;
  redirectUrl = null;
  private readonly KEY_TOKEN = 'mihmo-admin.token';

  authenticateStatus = new Subject<boolean>();

  constructor(
    private httpService: HttpService,
    private userService: UserService,
    private translocoService: TranslocoService,
    private poNotificaionService: PoNotificationService,
    private literalsService: LiteralsService,
    @Inject('auth') private firebase: AngularFireAuth
  ) {
    this.firebase.authState.subscribe({
      next: () => () => ({}),
      error: (err) => console.log('err', err),
    });
    this.literalsService.getLiterals().subscribe((literals: Literals) => {
      this.literals = literals;
    });
  }

  getAuthenticateStatusSubscription(): Observable<boolean> {
    return this.authenticateStatus.asObservable();
  }

  setAuthenticateStatusSubscription(state: boolean): void {
    return this.authenticateStatus.next(state);
  }

  async authenticateFirebase(params: LoginForm) {
    return this.firebase
      .signInWithEmailAndPassword(params.username, params.password)
      .then(async (response) => {
        return response.user.getIdToken().then(async (token) => token);
      });
  }

  public authenticate(params: LoginForm): any {
    this.clearLocalStorage();
    return from(this.authenticateFirebase(params))
      .pipe(
        tap((token) => {
          if (!token) return;
          this.setToken(token);
        }),
        switchMap((token) =>
          this.getPermissions(token).pipe(
            catchError((err) => throwError(() => err))
          )
        )
      )
      .subscribe({
        next: async () => {
          this.setAuthenticateStatusSubscription(true);
        },
        error: (err) => {
          if (err?.code) this.handleAuthError(err.code);

          this.clearLocalStorage();
          this.setAuthenticateStatusSubscription(false);
        },
      });
  }

  getPermissions(token: string) {
    return this.getPermissionsAndMenu().pipe(
      tap((permissionAndMenuList) => {
        const user = this.userService.createUserFromToken(token);
        this.setLang(user);
        this.userService.setUser(user);

        const permissionAndMenuListMapped = {
          ...user,
          permissions: permissionAndMenuList?.permissions,
          menu: permissionAndMenuList?.menu,
          roleType: permissionAndMenuList?.roleType,
          companiesIds: permissionAndMenuList?.companiesIds,
          medicCrm: permissionAndMenuList?.medicCrm,
          roleName: permissionAndMenuList?.roleName,
          medicUf: permissionAndMenuList?.medicUf,
        };

        this.userService.setUser(permissionAndMenuListMapped);
      })
    );
  }

  getPermissionsAndMenu(): Observable<AuthMenuAndPermissions> {
    return this.httpService
      .disableApiPrefix()
      .post(`${environment.apiNest}/authentication/logon`, {});
  }

  public authenticateSso(token: string) {
    this.setToken(token);
    const user = this.userService.createUserFromToken(token);
    this.userService.setUser(user);
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    const jwtHelper = new JwtHelperService();
    return token && !jwtHelper.isTokenExpired(token);
  }

  public setToken(token: string): void {
    localStorage.setItem(this.KEY_TOKEN, token);
  }

  public getToken() {
    return localStorage.getItem(this.KEY_TOKEN);
  }

  public logout() {
    localStorage.clear();
    sessionStorage.clear();
  }

  public setLang(user: UserModel) {
    const { lang } = user;

    this.translocoService.load(lang);
    localStorage.setItem('lang', lang);
  }

  private clearLocalStorage() {
    localStorage.removeItem('mihmo-admin.user');
    localStorage.removeItem('mihmo-admin.token');
    sessionStorage.removeItem('mihmo-admin.token_twilio');
    sessionStorage.removeItem('mihmo-accompaniment-selected');
  }

  handleAuthError(errorCode: string) {
    const errorMessages: { [key: string]: string } = {
      'auth/invalid-email': 'Email inválido. Verifique e tente novamente.',
      'auth/user-disabled':
        'Esta conta foi desativada. Entre em contato com o suporte.',
      'auth/user-not-found':
        'Usuário não encontrado. Verifique o email ou cadastre-se.',
      'auth/wrong-password': 'Senha incorreta. Tente novamente.',
      'auth/email-already-in-use':
        'Este email já está em uso. Tente outro ou recupere sua senha.',
      'auth/weak-password': 'Senha fraca. Escolha uma senha mais segura.',
      'auth/missing-email': 'Informe um email para continuar.',
      'auth/missing-password': 'Informe a senha para continuar.',
      'auth/invalid-credential':
        'Credenciais inválidas. Verifique email e senha.',
      'auth/too-many-requests':
        'Muitas tentativas. Tente novamente mais tarde.',
      'auth/network-request-failed':
        'Falha de conexão. Verifique sua internet.',
      'auth/requires-recent-login':
        'Faça login novamente para concluir essa ação.',
      'auth/operation-not-allowed':
        'Essa operação não está habilitada. Entre em contato com o suporte.',
    };

    const message = errorMessages[errorCode] || 'Ocorreu um erro inesperado.';
    this.poNotificaionService.warning({
      orientation: PoToasterOrientation.Top,
      message,
    });
  }
}
