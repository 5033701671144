import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { platformLangFactory, TitanModule } from '@recriar/titan';
import { environment } from '../../environments/environment';

@NgModule({
  imports: [
    CommonModule,
    TitanModule.forRoot({
      apiBaseUrl: environment.api,
      appStoragePrefix: 'mihmo-admin',
      platformLangFactory: platformLangFactory,
      disableCultureInterceptor: true,
      disableRetryPolicy: true,
    }),
  ],
})
export class CoreModule {}
