import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import * as AppActions from './app.actions';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { of } from 'rxjs';
// import { CompanySelectService } from '../features/company-select/services/company-select.service';

@Injectable()
export class AppEffects {
  loadApp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AppActions.loadApp),
      map(() => AppActions.loadAppSuccess({ app: [] }))
    )
  );

  // loadMenu$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(AppActions.changeCompany),
  //     mergeMap(({ company }) =>
  //       this.companySelectService.getMenu(company).pipe(
  //         map(menu => AppActions.loadMenuSuccess({ menu })),
  //         catchError(error => of(AppActions.loadMenuFailure({ error })))
  //       )
  //     )
  //   )
  // );

  constructor(private actions$: Actions) {}
}
