import { LOCALE_ID, NgModule, NgZone, PLATFORM_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
// locales
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
// app
import { CoreModule } from './core/core.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromApp from './+state/app.reducer';
import { AppEffects } from './+state/app.effects';
import { AppFacade } from './+state/app.facade';
import { environment } from '../environments';
import { HttpClientModule } from '@angular/common/http';
import { TranslocoRootModule } from './transloco-root.module';
import { AuthRootModule } from './auth/auth-root.module';
import {
  AngularFirestoreAttendance,
  AngularFirestoreAuth,
} from './firebase/firebase.factory';
import {
  FullRouterStateSerializer,
  StoreRouterConnectingModule,
} from '@ngrx/router-store';

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CoreModule,
    AuthRootModule,
    AppRoutingModule,
    HttpClientModule,
    StoreModule.forRoot(
      {},
      {
        metaReducers: !environment.production ? [] : [],
        runtimeChecks: {
          strictActionImmutability: true,
          strictStateImmutability: true,
        },
      }
    ),
    EffectsModule.forRoot([AppEffects]),
    // !environment.production ? StoreDevtoolsModule.instrument() : [],
    StoreModule.forFeature(fromApp.APP_FEATURE_KEY, fromApp.reducer),

    //TODO: Checar esse serializer Fullrouter
    StoreRouterConnectingModule.forRoot({
      serializer: FullRouterStateSerializer,
    }),
    TranslocoRootModule,
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  providers: [
    { provide: LOCALE_ID, useValue: 'pt' },
    AppFacade,
    {
      provide: 'auth',
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFirestoreAuth,
    },
    {
      provide: 'attendance',
      deps: [PLATFORM_ID, NgZone],
      useFactory: AngularFirestoreAttendance,
    },
  ],
})
export class AppModule {}

registerLocaleData(localePt);
