import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import {
  PoNotification,
  PoNotificationService,
  PoToasterOrientation,
} from '@po-ui/ng-components';
import { DialogService } from '../services';
import { Location } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class ErrorHandlerInterceptor implements HttpInterceptor {
  notificationTypes = ['success', 'warning', 'error', 'information'];

  constructor(
    protected notification: PoNotificationService,
    protected dialog: DialogService
  ) {}

  public err: any;

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const cloneRequest = request.clone({
      setHeaders: {
        'X-Mihmo-No-Error': 'true',
      },
    });

    return next.handle(cloneRequest).pipe(
      tap(
        (response: HttpEvent<any>) => {
          if (response instanceof HttpResponse) {
            this.processResponse(response);
          }
        },
        (error: HttpErrorResponse) => {
          this.processErrorResponse(error);
        }
      )
    );
  }

  processResponse(response: HttpResponse<any>) {
    if (response.body && response.body.totvsMessages) {
      const mihmoMessages = response.body.mihmoMessages;

      if (mihmoMessages instanceof Array) {
        mihmoMessages.forEach((mihmoMessage: any) => {
          this.showNotification(mihmoMessage);
        });
      } else {
        this.showNotification(mihmoMessages);
      }
    }
  }

  processErrorResponse(response: HttpErrorResponse) {
    const errorResponse =
      response.status !== 0
        ? response.error
        : {
            code: 0,
            message: 'Servidor não está respondendo.',
            detailedMessage: response.message,
          };

    // not show the notification when has NoError parameter on header of request.
    if (errorResponse && errorResponse.message) {
      this.showErrorNotification(errorResponse, response.status);
    }
  }

  private showNotification(totvsMessage: any) {
    if (this.notificationTypes.includes(totvsMessage.type)) {
      this.notification[totvsMessage.type]({
        message: totvsMessage.detail,
        orientation: PoToasterOrientation.Top,
      });
    }
  }

  protected showErrorNotification(
    errorResponse: any,
    status: number,
    location?: Location
  ) {
    const notificationAction =
      this.generateErrorNotificationAction(errorResponse);
    const message =
      errorResponse && errorResponse.details && errorResponse.details.length > 0
        ? errorResponse.details[0].message
        : errorResponse.message;
    const notification: PoNotification = {
      message: message,
      actionLabel: notificationAction.label,
      orientation: PoToasterOrientation.Top,
      duration: 5000,
    };

    switch (true) {
      case status >= 500 && status < 600:
        notification.message =
          'Algum erro ocorreu no servidor, tente novamente mais tarde. Caso persista, contacte um administrador';
        this.notification.error(notification);
        break;
      case status >= 400 && status < 500:
        this.notification.warning(notification);
        break;
      default:
        this.notification.error(notification);
        break;
    }
  }

  protected generateErrorNotificationAction(errorResponse: any) {
    let notificationAction;
    let notificationLabel;

    let notificationMessage = errorResponse.message.concat(
      ` ${errorResponse.detailedMessage}`
    );

    if (errorResponse.details && errorResponse.details instanceof Array) {
      errorResponse.details.forEach((detailError: any) => {
        notificationMessage += `\n${detailError.message}`;
      });
    }

    if (
      errorResponse.helpUrl &&
      !(errorResponse.detailedMessage || errorResponse.details)
    ) {
      notificationLabel = 'Ajuda';
      notificationAction = this.generateUrlHelpFunction(errorResponse.helpUrl);
    } else if (errorResponse.detailedMessage || errorResponse.details) {
      notificationLabel = 'Detalhes';
      notificationAction = this.generateDialogDetailFunction(
        errorResponse,
        notificationMessage
      );
    }
    return { label: notificationLabel, action: notificationAction };
  }

  private generateUrlHelpFunction(helpUrl: string) {
    return () => {
      window.open(helpUrl, '_blank');
    };
  }

  private generateDialogDetailFunction(
    errorResponse: any,
    notificationMessage: string
  ) {
    return () => {
      this.dialog.alert({
        title: errorResponse.code,
        message: notificationMessage,
        ok: errorResponse.helpUrl
          ? this.generateUrlHelpFunction(errorResponse.helpUrl)
          : undefined,
      });
    };
  }
}
