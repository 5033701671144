import { createAction, props } from '@ngrx/store';
import { AppEntity } from './app.models';
import { PoMenuItem } from '@po-ui/ng-components';
import { CompanySimplified } from '@recriar/mihmo-interfaces';

export const loadApp = createAction('[App] Load App');

export const loadAppSuccess = createAction('[App] Load App Success', props<{ app: AppEntity[] }>());

export const loadAppFailure = createAction('[App] Load App Failure', props<{ error: any }>());

export const loading = createAction(
  '[App] Loading App',
  props<{ loading: boolean; message?: string }>()
);

export const changeCompany = createAction(
  '[App] Change Company',
  props<{ company: CompanySimplified }>()
);

export const loadMenuSuccess = createAction(
  '[App] Load Menu Success',
  props<{ menu: PoMenuItem[] }>()
);

export const loadMenuFailure = createAction('[App] Load Menu Failure', props<{ error: any }>());

export const changeCountry = createAction('[App] Change Country', props<{ bacen: string }>());

export const loadCountrySuccess = createAction(
  '[App] Load Country Success',
  props<{ bacen: string }>()
);

export const loadCountryFailure = createAction(
  '[App] Load Country Failure',
  props<{ error: any }>()
);

export const changeConditions = createAction(
  '[App] Change Conditions',
  props<{ conditionsShared: any }>()
);
