<ni-page>
  <!-- HEADER -->
  <ni-page-header
    *ngIf="hasPageHeader()"
    [ngClass]="{ 'first-header': showHeaderWithAdditionInfo }"
    [p-back-url]="backUrl"
    [p-breadcrumb]="breadcrumb"
    [p-title]="title"
    [loading]="loading"
    style=""
  >
    <!-- OPERATIONS -->
    <div class="po-page-header-actions">
      <!--
	TODO: essa página deve ser descontinuada para utilizarmos a do PO-UI.
	 Caso não seja, deve ser incorporado um mecanismo de tradução
      -->
      <po-input
        (p-change-model)="searchChanged$.next($event)"
        *ngIf="enableSearch"
        p-icon="po-icon-search"
        p-placeholder="Pesquisar"
      ></po-input>

      <po-dropdown
        *ngIf="actions.length > limitPrimaryActions"
        [p-actions]="dropdownActions"
        p-label="Outras ações"
      >
      </po-dropdown>

      <po-button
        (p-click)="callAction(actions[2])"
        *ngIf="actions.length === 3 && actions[2] && !isMobile"
        [p-disabled]="actionIsDisabled(actions[2])"
        [p-label]="actions[2].label"
      >
      </po-button>

      <po-button
        (p-click)="callAction(actions[1])"
        *ngIf="actions[1] && (actions.length === 2 || !isMobile)"
        [p-disabled]="actionIsDisabled(actions[1])"
        [p-label]="actions[1].label"
      >
      </po-button>

      <po-button
        (p-click)="callAction(actions[0])"
        *ngIf="actions[0]"
        [p-disabled]="actionIsDisabled(actions[0])"
        [p-icon]="actions[0].icon"
        [p-label]="actions[0].label"
        p-kind="primary"
      >
      </po-button>
    </div>

    <div
      *ngIf="!showHeaderWithAdditionInfo && userSelected"
      class="po-pull-right po-p-1"
      style="display: flex; align-items: center; gap: 15px"
    >
      <a (click)="openUserListModal()" class="cursor-pointer">
        {{ userSelected?.name }}
      </a>

      <a (click)="openPopover()" class="po-icon po-icon-info cursor-pointer"></a>

      <po-button
        (p-click)="limesurveyModal.open()"
        *ngIf="showQuizInitial"
        p-label="Pesquisa de Saúde"
        p-small="true"
        p-kind="primary"
      ></po-button>

      <ni-page-notification-button
        (sendPushNotification)="emitSendPushNotification($event)"
        [userLogged]="userLogged"
        [userSelected]="userSelected"
      >
      </ni-page-notification-button>

<!--      <po-popover class="popover-custom-width" [p-target]="userInfo" style="position: absolute">-->
<!--        <ni-page-information-->
<!--          (calendlyLink)="emitCalendlyLink($event)"-->
<!--          (userResponsibleModal)="openUserResponsibleModal()"-->
<!--          [userLogged]="userLogged"-->
<!--          [userSelected]="userSelected"-->
<!--        ></ni-page-information>-->
<!--      </po-popover>-->
    </div>

    <div *ngIf="showHeaderWithAdditionInfo && userSelected" class="subheader">
      <div class="d-flex align-items-center" style="overflow-y: auto">
        <div class="d-flex align-items-center header-left">
          <a (click)="openUserListModal()" class="title-very-large-heavy bold po-pr-3">
            {{ userSelected?.name }}
          </a>

          <a
            #userInfo
            (click)="openPopover()"
            class="icon-detail-user po-icon po-icon-info cursor-pointer po-pr-5"
          ></a>

          <div class="d-flex align-items-center info-left">
            <span class="title-very-small gray">
              <span>miHmado desde</span>
              <span class="title-medium bold black">
                {{ userSelected.creationDate | date: 'LLL/Y' }}
              </span>
            </span>
            <span class="title-very-small gray">
              <span>Idade</span>
              <span class="title-medium bold black">
                {{ userSelected.age }}
              </span>
            </span>
            <span class="title-very-small gray">
              <span>Empresa</span>
              <span class="title-medium bold black">
                {{ userSelected?.company?.name ||' ' }}
              </span>
            </span>
            <span class="title-very-small gray">
              <span>Última Ação</span>
              <span class="title-medium bold black"> {{ userSelected?.lastAction ? (userSelected?.lastAction | date: 'dd/MM/yyyy') : ' ' }} </span>
            </span>
          </div>
        </div>

        <div
          *ngIf="userSelected"
          class="po-p-1"
          style="display: flex; align-items: center; gap: 15px; margin-left: auto"
        >
          <po-button
            (p-click)="limesurveyModal.open()"
            *ngIf="showQuizInitial"
            p-label="Pesquisa de Saúde"
            p-small="true"
            p-kind="primary"
          ></po-button>

          <ni-page-notification-button
            (sendPushNotification)="emitSendPushNotification($event)"
            [userLogged]="userLogged"
            [userSelected]="userSelected"
          >
          </ni-page-notification-button>

<!--          <po-popover [p-target]="userInfo" style="position: absolute">-->
<!--            <ni-page-information-->
<!--              (calendlyLink)="emitCalendlyLink($event)"-->
<!--              (userResponsibleModal)="openUserResponsibleModal()"-->
<!--              [userLogged]="userLogged"-->
<!--              [userSelected]="userSelected"-->
<!--            ></ni-page-information>-->
<!--          </po-popover>-->
        </div>
      </div>
    </div>
  </ni-page-header>

  <!-- CONTENT -->
  <ni-page-content>
    <ng-content></ng-content>
  </ni-page-content>
</ni-page>

<ni-limesurvey-initial-quiz-modal
  #limesurveyModal
  [customerSelected]="userSelected"
  [limesurveyList]="limesurveyList"
></ni-limesurvey-initial-quiz-modal>
