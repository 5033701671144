import { Inject, Injectable } from '@angular/core';
import { from, Observable, throwError } from 'rxjs';
import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { AppStoragePrefixToken } from '../services/tokens';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/auth';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Router } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class AuthInterceptor implements HttpInterceptor {
  private readonly STORAGE_KEY = 'token';
  private isRefreshing = false;

  constructor(
    @Inject(AppStoragePrefixToken) private storagePrefix: string,
    @Inject('auth') public firebase: AngularFireAuth,
    private router: Router
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.isAuthenticated()) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.getToken()}`,
        },
      });
    }

    return next.handle(request).pipe(
      catchError((error) => {
        if (
          (error.status === 401 || error.status === 403) &&
          !this.isRefreshing
        ) {
          this.isRefreshing = true;

          return this.refreshToken().pipe(
            switchMap((newToken) => {
              request = request.clone({
                setHeaders: { Authorization: `Bearer ${newToken}` },
              });
              return next.handle(request);
            }),
            catchError((err) => {
              this.isRefreshing = false;
              return throwError(() => {
                console.error(
                  err,
                  'Sessão expirada. É necessário refazer o login.'
                );
                new Error('Sessão expirada. É necessário refazer o login.');
                new Error('Sessão expirada. É necessário refazer o login.');
              });
            })
          );
        }
        return throwError(() => error);
      })
    );
  }

  public isAuthenticated(): boolean {
    const token = this.getToken();
    const jwtHelper = new JwtHelperService();
    return token && !jwtHelper.isTokenExpired(token);
  }

  refreshToken(): Observable<string> {
    return from(this.firebase.currentUser).pipe(
      switchMap((user) => {
        if (user) {
          return from(user.getIdToken(true)).pipe(
            tap((newToken) =>
              localStorage.setItem('mihmo-admin.token', newToken)
            )
          );
        } else {
          return throwError(() => new Error('Usuário não autenticado'));
        }
      })
    );
  }

  private getToken() {
    return localStorage.getItem(`${this.storagePrefix}.${this.STORAGE_KEY}`);
  }

  private clearToken() {
    return localStorage.removeItem(`${this.storagePrefix}.${this.STORAGE_KEY}`);
  }
}
