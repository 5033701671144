import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AppGuard } from './app.guard';
import { AuthGuard } from '@recriar/mihmo-auth';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: 'auth',
    loadChildren: () => import('@recriar/mihmo-auth').then(m => m.MihmoAuthModule),
  },
  {
    path: '',
    canActivate: [AuthGuard, AppGuard],
    loadChildren: () => import('./features/features.module').then(m => m.FeaturesModule),
  },
  { path: '**', redirectTo: 'home', pathMatch: 'full' },
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: '' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
