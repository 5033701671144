import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'dateCustom',
  standalone: true,
})
export class DateCustomPipe implements PipeTransform {
  transform(value: Date) {
    if (this.isToday(value)) {
      return formatDate(value, "HH'h'mm", 'pt-BR');
    } else if (this.isYesterday(value)) {
      return 'Ontem';
    } else if (this.isBeforeYesterday(value)) {
      return 'Anteontem';
    } else if (!value) {
      return '';
    } else {
      return formatDate(value, "dd/MM/YYYY HH'h'mm", 'pt-BR');
    }
  }

  isToday(date: Date): boolean {
    const today = new Date();
    const dateValue = new Date(date);
    today.setHours(0, 0, 0, 0);
    dateValue.setHours(0, 0, 0, 0);

    return dateValue.getTime() === today.getTime();
  }

  isYesterday(date: Date): boolean {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);
    yesterday.setHours(0, 0, 0, 0);

    const dateValue = new Date(date);
    dateValue.setHours(0, 0, 0, 0);

    return dateValue.getTime() === yesterday.getTime();
  }

  isBeforeYesterday(date: Date): boolean {
    const beforeYesterday = new Date();
    beforeYesterday.setDate(beforeYesterday.getDate() - 2);
    beforeYesterday.setHours(0, 0, 0, 0);

    const dateValue = new Date(date);
    dateValue.setHours(0, 0, 0, 0);

    return dateValue.getTime() === beforeYesterday.getTime();
  }
}
