import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from '@recriar/mihmo-auth';
import { AppGuard } from './app.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('@recriar/mihmo-auth').then((m) => m.MihmoAuthModule),
  },
  {
    path: '',
    canActivate: [AuthGuard, AppGuard],
    loadChildren: () =>
      import('./features/features.module').then((m) => m.FeaturesModule),
  },
  {
    path: 'modal',
    outlet: 'modal',
    canActivate: [AuthGuard, AppGuard],
    loadChildren: () =>
      import(
        './features/main-information-modal/main-information-modal.module'
      ).then((m) => m.MainInformationModalModule),
  },
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  imports: [
    RouterModule.forRoot(routes, {
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
