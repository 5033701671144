import { Inject, Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';
import { PlatformLanguageToken } from '@recriar/titan';
import { TranslocoService } from '@ngneat/transloco';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppGuard implements CanActivate {
  constructor(
    @Inject(PlatformLanguageToken) private lang: string,
    private translocoService: TranslocoService
  ) {}

  canActivate(): Observable<boolean> {
    return this.translocoService.load(this.lang).pipe(map(() => true));
  }
}
