<div class="mihmo-login">
  <div class="po-row po-h-100">
    <div class="po-md-6 row-left">
      <img alt="logo-mihmo" class="banner" src="/assets/images/login-banner.svg" />
    </div>
    <div class="po-md-6 container-login">
      <div class="mihmo-login-form-container">
        <div class="display-block po-text-center">
          <img alt="logo-mihmo" class="logo" src="/assets/images/logo-mihmo-colored.svg" width="90" />
          <span class="login-title">Olá <span class="smile-icon">:)</span> </span> <br>
          <span class="login-subtitle">Faça seu login</span>
        </div>

        <form [formGroup]="loginForm" class="login">
          <div class="po-row ">
            <div class="po-sm-12">
              <po-login
                formControlName="username"
                name="username"
                p-placeholder="{{ literals.loginPlaceholder }}"
                p-required
              ></po-login>
              <po-password
                (keyup.enter)="onSubmit(loginForm)"
                formControlName="password"
                name="password"
                p-placeholder="{{ literals.passwordPlaceholder }}"
                p-required
              ></po-password>
            </div>
            <po-switch
              class="user-remember"
              formControlName="keepAlive"
              name="keepAlive"
              p-label-off="{{ literals.rememberUserLabel }}"
              p-label-on="{{ literals.rememberUserLabel }}"
              p-label-position="0"
            ></po-switch>
            <po-button
              [p-loading]="isLoading"
              (click)="onSubmit(loginForm)"
              class="po-sm-12"
              p-label="{{ literals.enter }}"
              p-kind="primary"
            ></po-button>
            <div (click)="open()" class="po-link recovery-text">
              {{ literals.accessRecover }}
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="app-version">{{ literals.version }}</div>

<auth-recover-password #recoverPassword></auth-recover-password>
